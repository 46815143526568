import React, { useState, useEffect } from "react";
import { useProjectDetails, fetcher } from "../data/projectHook";
import useSWR from "swr";

const Summary = (props) => {
  const { content } = useProjectDetails();
  const { data, mutate, error } = useSWR(
    `/api/pv/fetchTopics/${content.project_id}`,
    fetcher
  );

  const [theme, setActiveTheme] = useState(0);
  const [canMove, setCanMove] = useState({ prev: false, next: false });
  const types = ["HOOK", "ENGAGE", "CONNECT"];

  useEffect(() => {
    if (data) {
      let length = data.themes.length - 1;

      if (theme > 0 && theme < length) {
        setCanMove({ prev: true, next: true });
      } else if (theme > 0 && theme === length) {
        setCanMove({ prev: true, next: false });
      } else if (theme === 0 && length > 0) {
        setCanMove({ prev: false, next: true });
      }
    }
  }, [data, theme]);

  const increment = () => {
    if (data.themes.length - 1 > theme) {
      setActiveTheme(theme + 1);
    }
  };

  const decrement = () => {
    if (theme > 0) {
      setActiveTheme(theme - 1);
    }
  };

  if (!data && !error) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  if (data) {
    const activeTheme = data.themes[theme];
    return (
      <div className="summary-page">
        <div className="summary">
          <div className="top-bar">
            <h4 className="pretitle">Theme</h4>
            <h4 className="title">{activeTheme.name}</h4>
          </div>
          <div className="topic-list-wrapper">
            {types.map((t) => (
              <TopicList
                key={t}
                type={t}
                topics={activeTheme.topics.filter((topic) => topic.level === t)}
              />
            ))}
          </div>
          <div
            onClick={decrement}
            className={`arrow-btn btn-prev ${
              canMove.prev ? "can-move" : "cannot-move"
            }`}
          >
            <img src="/icons/arrow-left.svg" alt="Arrow Left - Go Previous" />
          </div>
          <div
            onClick={increment}
            className={`arrow-btn btn-next ${
              canMove.next ? "can-move" : "cannot-move"
            }`}
          >
            <img src="/icons/arrow-right.svg" alt="Arrow Right - Go Next" />
          </div>
        </div>
      </div>
    );
  }
};

const TopicList = ({ type, topics }) => (
  <div className="topic-listing">
    <h3 className="title">{type}</h3>
    <div className="topic-line-items">
      <div className="topic-line-item header">
        <p className="text">Topics</p>
        <p className="text">Mediums</p>
      </div>
      {topics.map((t) => (
        <div className="topic-line-item" key={t.text}>
          <p className="text">{t.text}</p>
          <div className="icons">
            {t.channels.map((c) => (
              <img src={`/icons/social/${c}-ACTIVE.svg`} alt={c} />
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Summary;
