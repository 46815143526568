import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AddUser = () => {
  const [org, setorg] = useState("");
  const [name, setname] = useState("");
  const [pass, setpass] = useState("");
  const [isDifferentOrg, setDifferentOrg] = useState(false);
  const [hasSubmit, setHasSubmit] = useState(false);

  const submit = () => {
    if (name === "" || pass == "") {
      alert("Please input a name first");
      return;
    }

    axios
      .post("/api/pv/addNewUser", {
        name: name,
        password: pass,
        email: name,
        diffOrg: org === "" ? undefined : org,
      })
      .then((resp) => {
        setname("");
        setpass("");
        setorg("");
        console.log(resp.data);
        setHasSubmit(true);
      })
      .catch((e) => console.log(e.message));
  };

  return (
    <div className="new-user">
      <h1>Add a new user</h1>
      {hasSubmit ? (
        <div className="alert">
          <p>
            Thank you, you may now instruct the user to log in with the
            credentials provided earlier.
          </p>
          <div className="btns">
            <Link to="/">
              <button className="primary">Return Home</button>
            </Link>
            <button onClick={() => setHasSubmit(false)}>
              Add Another User
            </button>
          </div>
        </div>
      ) : (
        <div className="form">
          <label>
            Email Address:
            <input
              type="text"
              value={name}
              placeholder="email name"
              onChange={(e) => setname(e.target.value)}
            />
          </label>
          <label>
            Password:
            <input
              type="text"
              value={pass}
              placeholder="pass"
              onChange={(e) => setpass(e.target.value)}
            />
          </label>
          <div className="input-holder">
            <label>
              <input
                type="checkbox"
                checked={isDifferentOrg}
                onChange={() => setDifferentOrg(!isDifferentOrg)}
              />
              Is this user part of a different organisation?
            </label>
          </div>
          {isDifferentOrg && (
            <label>
              Organisation Name:
              <input
                type="text"
                value={org}
                placeholder="org"
                onChange={(e) => setorg(e.target.value)}
              />
            </label>
          )}
          <div className="btns">
            <button onClick={submit} className="btn one">
              Add User
            </button>
            <Link to="/">
              <button className="btn two">Return home</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUser;
