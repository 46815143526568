import React, { useState, useEffect } from "react";
import { PersonaSelector } from "./persona";
import { Page } from "./misc";
import { useProjectDetails } from "../data/projectHook";
import axios from "axios";

export const GTB = ({ state, mutate }) => {
  const { content } = useProjectDetails();
  const [to, setTo] = useState("");
  const [by, setBy] = useState("");

  useEffect(() => {
    if (state && state.personas && state.personas.length > 0) {
      const active = state.personas[content.activePersona];
      if (active && active.getToBy) {
        setTo(active.getToBy.to);
        setBy(active.getToBy.by);
      } else {
        setTo("");
        setBy("");
      }
    }
  }, [state, content]);

  const sendGetToBy = async () => {
    let active_gtb = state.personas[content.activePersona].getToBy || "";

    if (active_gtb.to === to && active_gtb.by === by) {
      return;
    }

    try {
      await axios.put(
        `/api/pv/defineGetToBy/${state.personas[content.activePersona]._id}`,
        {
          to,
          by,
        }
      );
      mutate();
      setTo("");
      setBy("");
    } catch (e) {
      console.log("Error setting getToBy", e.message);
    }
  };

  if (state.personas.length === 0) {
    return (
      <Page>
        <p>Add personas first</p>
      </Page>
    );
  }

  return (
    <Page>
      <div className="gtb-wrapper">
        <div className="row row-1">
          <h3>I want</h3>
          <PersonaSelector />
        </div>
        <div className="row row-2">
          <h3>to</h3>
          <div className="input-wrapper">
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
            <p className="label">
              what we want the audience to do, think, or feel.
            </p>
          </div>
        </div>
        <div className="row row-3">
          <h3>by</h3>
          <div className="input-wrapper">
            <input
              type="text"
              value={by}
              onChange={(e) => setBy(e.target.value)}
            />
            <p className="label">what we can and should do.</p>
          </div>
        </div>
        <button onClick={sendGetToBy} className="btn primary">
          Save
        </button>
      </div>
    </Page>
  );
};
