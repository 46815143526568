import React, { useState, useEffect } from "react";
import useSWR, { mutate } from "swr";
import axios from "axios";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Page } from "./misc";
import { useProjectDetails, fetcher } from "../data/projectHook";
import { HTML5Backend as Backend } from "react-dnd-html5-backend";
import produce from "immer";

const ThemeBoard = () => {
  const [showThemeForm, setThemeForm] = useState(true);
  const { content } = useProjectDetails();
  const { data, mutate, error, isValidating } = useSWR(
    `/api/pv/fetchTopics/${content.project_id}`,
    fetcher,
    {
      refreshInterval: 10000,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );

  useEffect(() => {
    if (data && data.themes.length > 0) {
      setThemeForm(false);
    }
  }, [data]);

  if (!data && !error) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>There was an error: {error.message}</p>;
  }

  if (data.topics.length < 1 && data.themes.length < 1) {
    return <p>Please add some topics first...</p>;
  }

  return (
    <Page>
      <DndProvider backend={Backend}>
        <div className="theme-board">
          <div className="themes">
            <div className="theme-list">
              {showThemeForm && (
                <div className="modal-wrapper inner">
                  <NewThemeForm
                    length={data.themes.length}
                    show={setThemeForm}
                    project_id={content.project_id}
                  />
                </div>
              )}
              {data.themes.map((theme, index) => (
                <ThemeComponent
                  data={theme}
                  key={theme._id}
                  mutate={mutate}
                  state={data}
                  showPlus={data.themes.length - 1 === index}
                  addTheme={() => setThemeForm(true)}
                  iv={isValidating}
                />
              ))}
            </div>
          </div>
          <TopicWrapper
            topics={data.topics}
            state={data}
            mutate={mutate}
            iv={isValidating}
          />
        </div>
      </DndProvider>
    </Page>
  );
};

const TopicWrapper = ({ topics, state, mutate, iv }) => {
  const [{ isOver }, dropOut] = useDrop(() => ({
    accept: "TOPIC",
    drop: async (item, monitor) => {
      if (item.theme) {
        removeTopicFromTheme(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const removeTopicFromTheme = (item) => {
    let newState = produce(state, (draft) => {
      draft.themes.forEach((theme) => {
        if (theme._id === item.theme) {
          theme.topics.filter((topic) => topic._id !== item.id);
        }
      });

      draft.topics.push({
        _id: item.id,
        persona: { colorCode: item.color },
        text: item.text,
      });
    });

    axios
      .put("/api/pv/updateTopicParticulars/REMOVE_FROM_THEME", {
        topic_id: item.id,
      })
      .then(() => {
        mutate(newState, false);
      })
      .catch((e) => console.log(e.message));
  };

  return (
    <div className={`topics ${isOver ? "can-drop" : ""}`} ref={dropOut}>
      {topics.map((t) => (
        <TopicItem
          text={t.text}
          color={t.persona.colorCode}
          key={t._id}
          id={t._id}
          level={t.level}
          dragAllowed={!iv}
        />
      ))}
    </div>
  );
};

const ThemeComponent = ({ data, state, mutate, showPlus, addTheme, iv }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "TOPIC",
    drop: async (item, _) => {
      if (item.theme === data._id) {
        removeTypeFromTopic(item);
      } else {
        addTopicToTheme(item);
      }
    },
    canDrop: (item, _) => {
      // return item.theme !== data._id;
      return true;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  //TODO FIX
  const removeTypeFromTopic = async (item) => {
    let newState = produce(state, (draft) => {
      draft.themes.forEach((theme) => {
        if (theme._id === data._id) {
          theme.topics.forEach((topic) => {
            if (topic._id === item.id) {
              item.level = "NULL";
            }
          });
        }
      });
    });

    //TODO axios update

    mutate(newState, false);
  };

  const addTopicToTheme = async (item) => {
    let newState = produce(state, (draft) => {
      draft.themes.forEach((theme) => {
        // first, check if current topic has theme, if so, remove from that theme
        if (item.theme !== undefined) {
          if (theme._id === item.theme) {
            theme.topics.filter((t) => t._id !== item.id);
          }
        } else {
          // else, we assume that the topic came from the unfiltered section
          draft.topics = draft.topics.filter((topic) => topic._id !== item.id);
        }

        if (theme._id === data._id) {
          theme.topics.push({
            _id: item.id,
            persona: {
              colorCode: item.color,
            },
            text: item.text,
            level: "NULL",
          });
        }
      });
    });

    let requestObject = {
      topic_id: item.id,
      level: item.level,
      theme_id: data._id,
    };

    if (item.theme !== undefined) {
      requestObject.old_theme = item.theme;
    }

    try {
      await axios.put(
        "/api/pv/updateTopicParticulars/ADD_THEME",
        requestObject
      );

      mutate(newState, false);
    } catch (e) {
      console.log("Error dropping", e.message);
    }
  };

  return (
    <div className="theme">
      <div className="title-bar">
        <p className="title">{data.name}</p>
        <p className="desc">{data.description}</p>
      </div>
      <div
        className={`topic-list ${isOver ? "is-over" : ""} ${
          canDrop ? "can-drop" : ""
        }`}
        ref={drop}
      >
        {data.topics.length > 0 &&
          data.topics
            .filter((t) => t.level === "NULL")
            .map((t) => (
              <TopicItem
                text={t.text}
                color={t.persona.colorCode}
                id={t._id}
                level={t.level}
                key={t._id}
                theme={data._id}
                dragAllowed={!iv}
              />
            ))}
      </div>
      {data.topics.length > 0 && (
        <div className="sorted-topics">
          <ChildDropZone
            topics={data.topics}
            name="HOOK"
            theme_id={data._id}
            state={state}
            mutate={mutate}
            iv={iv}
          />
          <ChildDropZone
            topics={data.topics}
            name="ENGAGE"
            theme_id={data._id}
            state={state}
            mutate={mutate}
            iv={iv}
          />
          <ChildDropZone
            topics={data.topics}
            name="CONNECT"
            theme_id={data._id}
            state={state}
            mutate={mutate}
            iv={iv}
          />
        </div>
      )}
      {showPlus && (
        <div className="add-theme" onClick={addTheme}>
          +
        </div>
      )}
    </div>
  );
};

const TopicItem = ({ text, color, id, level, theme, dragAllowed }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TOPIC",
    item: { id, level, color, text, theme },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    canDrag: () => {
      return dragAllowed;
    },
  }));
  return (
    <div className="topic-item" style={{ backgroundColor: color }} ref={drag}>
      <p>{text}</p>
    </div>
  );
};

const NewThemeForm = ({ length, show, project_id }) => {
  const [name, setName] = useState("");
  const [description, setDesc] = useState("");

  const addTheme = () => {
    try {
      axios.post("/api/pv/addTheme", { name, description, project_id });
      show(false);
      mutate(`/api/pv/fetchTopics/${project_id}`);
    } catch (e) {
      console.log("Error adding theme", e.message);
    }
  };

  return (
    <div className="new-theme-form">
      <div className="intro">
        <h3>Add a new theme</h3>
        <p>Themes help group your topics into cohesive ideas.</p>
      </div>
      <input
        type="text"
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="Name"
      />
      <input
        type="text"
        onChange={(e) => setDesc(e.target.value)}
        value={description}
        placeholder="Description"
      />
      <div className="btns">
        <button onClick={addTheme}>Add Theme</button>
        <button
          onClick={() => show(false)}
          disabled={length < 1}
          className="alt"
        >
          Hide Form
        </button>
      </div>
    </div>
  );
};

const ChildDropZone = ({ name, theme_id, topics, state, mutate, iv }) => {
  const [{ isOver, canDrop }, dropChild] = useDrop(() => ({
    accept: "TOPIC",
    drop: async (item, monitor) => {
      addTopicLevel(item);
    },
    canDrop: (item) => {
      if (item.theme === theme_id) {
        return true;
      } else {
        return false;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const addTopicLevel = async (item) => {
    let newState = produce(state, (draft) => {
      draft.themes.forEach((theme) => {
        if (theme._id === item.theme) {
          theme.topics.forEach((topic) => {
            if (topic._id === item.id) {
              topic.level = name;
            }
          });
        }
      });
    });

    try {
      await axios.put("/api/pv/updateTopicParticulars/SET_LEVEL", {
        topic_id: item.id,
        level: name,
      });
      mutate(newState, false);
    } catch (e) {
      console.log("Error dropping", e.message);
    }
  };

  return (
    <div
      className={`child-drop-zone ${canDrop ? "can-drop" : "cannot-drop"} ${
        isOver && canDrop ? "is-over" : "is-not-over"
      }`}
      ref={dropChild}
    >
      <h5>{name}</h5>
      <div className="cdz-topics">
        {topics.length > 0 &&
          topics
            .filter((t) => t.level === name)
            .map((t) => (
              <TopicItem
                text={t.text}
                color={t.persona.colorCode}
                id={t._id}
                key={t._id}
                theme={theme_id}
                level={name}
                dragAllowed={!iv}
              />
            ))}
      </div>
    </div>
  );
};

export default ThemeBoard;
