import React, { useState } from "react";
import axios from "axios";

const AddOrg = () => {
  const [input, setInput] = useState("");

  const submit = () => {
    if (input === "") {
      alert("Please input a name first");
      return;
    }

    axios
      .post("/api/pv/addOrganisation", { name: input })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((e) => console.log(e.message));
  };

  return (
    <div className="pv">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button onClick={submit}>Add Organisation</button>
    </div>
  );
};

export default AddOrg;
