import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { useAuth } from "../data/auth";
import { NewBrandForm, NewProjectForm } from "../components/forms";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Dashboard = () => {
  const { data, error } = useSWR("/api/pv/listBrands", fetcher);
  const { dispatch } = useAuth();
  const [showBrandForm, toggleBrandForm] = useState(false);

  if (!data && !error) {
    return <p>Loading...</p>;
  }

  if (error) {
    dispatch({ type: "REMOVE_TOKEN" });
    return <p>There was an error {error.message}</p>;
  }

  if (data.length === 0) {
    return (
      <div className="dashboard">
        <div className="dashboard-sidebar">
          <img src="/logo.png" alt="The Good Content Tool" />
          <p>
            No active brands under this organisation. Add a new one via this
            form.
          </p>
        </div>
        <div className="dashboard-main centered">
          <NewBrandForm toggle={toggleBrandForm} />
        </div>
      </div>
    );
  }

  if (data) {
    return (
      <div className="dashboard">
        <div className="dashboard-sidebar">
          <img src="/logo.png" alt="The Good Content Tool" />
          <p>Hello there!</p>
          <p>
            This is a guided workshopping tool that helps you make sense of your
            audience, your social platforms and content opportunities.
          </p>
          <p>
            select a brand or project, or create a new one using their
            designated buttons.
          </p>
        </div>
        <div className="dashboard-main">
          <div className="top-btns">
            {showBrandForm ? (
              <div className="modal-wrapper">
                <NewBrandForm toggle={toggleBrandForm} />
              </div>
            ) : (
              <div className="btns new-brand">
                <Link to="/pv/user">
                  <button className="btn">Invite User</button>
                </Link>
                <button
                  onClick={() => {
                    toggleBrandForm(true);
                  }}
                  className="primary"
                >
                  New Brand
                </button>
              </div>
            )}
          </div>
          <div className="brand-items">
            {data.length < 0 ? (
              <p>You have no active brands</p>
            ) : (
              data.map((x) => <BrandItem brand={x} key={x._id} />)
            )}
          </div>
        </div>
      </div>
    );
  }
};

const BrandItem = ({ brand }) => {
  const [showProjectForm, toggleProjectForm] = useState(false);

  return (
    <div className="item" key={brand._id}>
      <h3>{brand.name}</h3>

      <div className="projects">
        <div className="top-row">
          <h4>Projects</h4>
          <button
            className="circle"
            onClick={() => toggleProjectForm(!showProjectForm)}
          >
            {showProjectForm ? "-" : "+"}
          </button>
        </div>
      </div>
      {showProjectForm && (
        <NewProjectForm toggle={toggleProjectForm} brand={brand._id} />
      )}

      {brand.projects && brand.projects.length > 0 ? (
        <div className="project-listing">
          {brand.projects.map((p) => (
            <Link to={`/project/${p._id}`} key={p._id}>
              <p>{p.name}</p>
            </Link>
          ))}
        </div>
      ) : (
        <p>No active campaigns, add one with the button above</p>
      )}
    </div>
  );
};

export default Dashboard;
