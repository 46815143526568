import React, { useState, useEffect } from "react";
import { useProjectDetails, useProjectState } from "../data/projectHook";
import { PersonaSelector } from "./persona";
import { Page } from "./misc";
import axios from "axios";
import produce from "immer";

export const Challenges = () => {
  const { content } = useProjectDetails();
  const active = content.personas[content.activePersona];

  if (!active) {
    return (
      <Page>
        <p>Please define a persona first</p>
      </Page>
    );
  }

  return (
    <Page>
      <div className="challenge">
        <div className="selector-wrapper">
          <p>What is stopping</p>
          <PersonaSelector />
          <p>from reaching their goals?</p>
        </div>
        <p className="subtext">
          Brands who are able to address their audiences' pain-points will earn
          their attention and trust.
        </p>
        <h3 className="title">Challenges</h3>
        <ConcernSection type="CHALLENGE" data={active} />
      </div>
      <div className="opportunity">
        <div className="selector-wrapper">
          <p>What will help</p>
          <PersonaSelector />
          <p>reach their goals?</p>
        </div>
        <p className="subtext">
          Brands who are able to provide value and be part of the solutions to
          their audiences' challenges will build credibility and further build
          trust and connection with their audiences.
        </p>
        <h3 className="title">Opportunities</h3>
        <ConcernSection type="OPPORTUNITY" data={active} />
      </div>
    </Page>
  );
};

const ConcernSection = ({ type, data }) => {
  const [input, setInput] = useState("");
  const [showForm, setForm] = useState(false);
  const [concerns, setConcerns] = useState([]);
  const { content } = useProjectDetails();
  const { state, mutateProjectState } = useProjectState(content.project_id);
  useEffect(() => {
    let c = data.concerns.filter((x) => x.type === type);
    setConcerns(c);
  }, [data, type]);

  const add = async () => {
    if (input === "") {
      return;
    }

    let newState = produce(state, (draft) => {
      draft.personas.forEach((p, index) => {
        if (p._id === data._id) {
          draft.personas[index].concerns.push({ text: input, type });
        }
      });
    });

    try {
      mutateProjectState(newState, false);
      await axios.post("/api/pv/addConcern", {
        text: input,
        type,
        persona: data._id,
      });

      mutateProjectState(newState, true);
      setInput("");
      setForm(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="concerns">
      {concerns.length > 0 &&
        concerns.map((concern) => (
          <div
            key={concern.text}
            className="concern-item"
            style={{ backgroundColor: data.colorCode }}
          >
            <p>{concern.text}</p>
          </div>
        ))}
      {showForm ? (
        <div className="concern-form">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button onClick={add} className="circle">
            +
          </button>
        </div>
      ) : (
        <button className="concern-form-btn" onClick={() => setForm(true)}>
          +
        </button>
      )}
      <span></span>
    </div>
  );
};
