import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuth } from "./data/auth";
import Dashboard from "./routes/dashboard";
import Login from "./routes/login";
import Project from "./routes/project";
import AddUser from "./routes/pv/newUser.js";
import AddOrg from "./routes/pv/newOrg.js";
import Cookies from "js-cookie";

function App() {
  const { dispatch } = useAuth();
  useEffect(() => {
    let token = Cookies.get("secureCookie");
    if (token !== undefined) {
      dispatch({ type: "SET_TOKEN", token: token, loggedIn: true });
    }
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/project/:project_id">
          <Project />
        </PrivateRoute>
        <Route path="/pv/user">
          <AddUser />
        </Route>
        <Route path="/pv/org">
          <AddOrg />
        </Route>
        <Route path="/login" component={Login} />
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  let { state } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
