import { Page } from "./misc";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { mutate } from "swr";
import { PersonaBreakdown } from "./persona";
import { SocialThemeIcon } from "./misc";

export const SocialLinking = ({ data, channels }) => {
  const [theme, setActiveTheme] = useState(0);
  const [canMove, setCanMove] = useState({ prev: false, next: false });
  const types = ["HOOK", "ENGAGE", "CONNECT"];

  useEffect(() => {
    let length = data.length - 1;
    console.log(data[theme]);

    if (theme > 0 && theme < length) {
      setCanMove({ prev: true, next: true });
    } else if (theme > 0 && theme === length) {
      setCanMove({ prev: true, next: false });
    } else if (theme === 0 && length > 0) {
      setCanMove({ prev: false, next: true });
    }
  }, [data, theme]);

  if (data.length === 0) {
    return <p>loading...</p>;
  }

  const increment = () => {
    if (data.length - 1 > theme) {
      setActiveTheme(theme + 1);
    }
  };

  const decrement = () => {
    if (theme > 0) {
      setActiveTheme(theme - 1);
    }
  };

  return (
    <Page>
      <div className="social-linking">
        <PersonaBreakdown />
        <div className="theme-item">
          <div className="top-bar">
            <h4 className="pretitle">Theme</h4>
            <h4 className="title">{data[theme].name}</h4>
          </div>
          {types.map((type) => (
            <TopicList
              key={type}
              type={type}
              channels={channels}
              topics={data[theme].topics.filter(
                (topic) => topic.level === type
              )}
            />
          ))}

          <div
            onClick={decrement}
            className={`arrow-btn btn-prev ${
              canMove.prev ? "can-move" : "cannot-move"
            }`}
          >
            <img src="/icons/arrow-left.svg" alt="Arrow Left - Go Previous" />
          </div>
          <div
            onClick={increment}
            className={`arrow-btn btn-next ${
              canMove.next ? "can-move" : "cannot-move"
            }`}
          >
            <img src="/icons/arrow-right.svg" alt="Arrow Right - Go Next" />
          </div>
        </div>
      </div>
    </Page>
  );
};

const TopicList = ({ topics, type, channels }) => (
  <div className="topic-list-wrapper">
    <h2 className="title">{type}</h2>
    {topics.length > 0 ? (
      <div className="topic-list">
        {topics.map((t) => (
          <TopicItem key={t} channels={channels} topic={t} />
        ))}
      </div>
    ) : (
      <p className="disclaimer">No topics have been added here yet.</p>
    )}
  </div>
);

const TopicItem = ({ topic, channels }) => {
  const addChannel = (chan) => {
    if (topic.channels.indexOf(chan) !== -1) {
      console.log("ALREADY EXISTS");
    } else {
      axios
        .put("/api/pv/updateTopicParticulars/ADD_CHANNEL", {
          topic_id: topic._id,
          channel: chan,
        })
        .then(() => {
          mutate(`/api/pv/fetchProjectState/${topic.project}`);
        })
        .catch((e) => console.log(e.message));
    }
  };

  return (
    <div className="topic-item-wrapper">
      <div
        className="topic-item"
        style={{ backgroundColor: topic.persona.colorCode }}
      >
        <p>{topic.text}</p>
      </div>
      <div className="channel-list">
        {channels.map((tc) => (
          <SocialThemeIcon
            name={tc}
            key={tc}
            active={topic.channels.indexOf(tc) === -1 ? false : true}
            add={() => addChannel(tc)}
          />
        ))}
      </div>
    </div>
  );
};
