import React, { useState, useEffect } from "react";
import { NewPersonaForm } from "./forms";
import { Page } from "./misc";
import { useProjectState, useProjectDetails } from "../data/projectHook";
import axios from "axios";
import produce from "immer";

export const PersonaContainer = () => {
  const { content } = useProjectDetails();
  const { state, mutateProjectState, error } = useProjectState(
    content.project_id
  );
  const [showPersonaForm, togglePersonaForm] = useState(false);

  if (!state && !error) {
    return <p>Loading...</p>;
  }

  return (
    <Page>
      <div className="persona-list">
        {state.personas.map((p, index) => (
          <PersonaItem
            key={p._id}
            role={p.role}
            id={p._id}
            name={p.name}
            goals={p.goals}
            mutate={mutateProjectState}
            color={p.colorCode}
            state={state}
            showPlusButton={
              state.personas.length - 1 === index &&
              !showPersonaForm &&
              state.personas.length < 3
            }
            togglePersonaForm={() => togglePersonaForm(true)}
          />
        ))}
        {showPersonaForm && (
          <NewPersonaForm
            project_id={content.project_id}
            state={state}
            mutate={mutateProjectState}
            toggle={togglePersonaForm}
          />
        )}
      </div>
    </Page>
  );
};

const PersonaItem = ({
  name,
  role,
  goals,
  id,
  mutate,
  state,
  color,
  showPlusButton,
  togglePersonaForm,
}) => {
  const [goal, setGoal] = useState("");
  const [showGoalForm, setShowGoalForm] = useState(false);

  const addGoal = () => {
    if (goal === "") {
      return;
    }

    let newState = produce(state, (draft) => {
      draft.personas.forEach((p, index) => {
        if (p._id === id) {
          draft.personas[index].goals.push({ text: goal });
        }
      });
    });

    try {
      mutate(newState, false);
      axios.post("/api/pv/addGoal", { text: goal, persona: id });
      //mutate(newState, true);
      setGoal("");
      setShowGoalForm(false);
    } catch (e) {
      /* handle error */
      console.log(e.message);
    }
  };

  return (
    <div className="persona-item">
      {showPlusButton && (
        <div className="add-persona-button" onClick={togglePersonaForm}>
          <p>+</p>
        </div>
      )}
      <img
        className="title-image"
        src="/icons/persona.svg"
        alt="An icon depicting a human face"
      />
      <div className="title-card" style={{ backgroundColor: color }}>
        <h1>{name}</h1>
        <p className="label">Persona Name</p>
        <h3>{role}</h3>
        <p className="label">what do they do</p>
      </div>

      <div className="goal-listing">
        <p className="title">What do they want to achieve?</p>
        {goals.length > 0 &&
          goals.map((g) => (
            <div
              key={g.text}
              className="goal-item"
              style={{ backgroundColor: color }}
            >
              <p>{g.text}</p>
            </div>
          ))}
        {goals.length < 3 && !showGoalForm && (
          <button className="add-goal" onClick={() => setShowGoalForm(true)}>
            +
          </button>
        )}
        {showGoalForm && (
          <div className="goal-form">
            <input
              type="text"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
            />
            <button onClick={addGoal} className="circle">
              +
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const PersonaBreakdown = () => {
  const { content, dispatch } = useProjectDetails();
  const [showBottom, toggleBottom] = useState(false);

  const persona = content.personas[content.activePersona];

  if (!persona) {
    return <p>Please define a persona first</p>;
  }

  if (persona.getToBy === undefined) {
    return <p>Please set a GTB for this persona first.</p>;
  }

  return (
    <div className="persona-breakdown">
      <div
        className="top-section"
        style={{ backgroundColor: persona.colorCode }}
      >
        <img
          src="/icons/persona.svg"
          alt="Person Icon"
          className="persona-icon"
        />
        <div
          className="title-section"
          onClick={() => dispatch({ type: "INCREMENT_AP" })}
        >
          <h1>{persona.name}</h1>
          <h3>{persona.role}</h3>
          <small>
            <em>Click to toggle persona</em>
          </small>
        </div>
        <div className="goals">
          <p className="title">Goals:</p>
          {persona.goals.length > 0 &&
            persona.goals.map((g) => (
              <p className="goal" key={g._id}>
                {g.text}
              </p>
            ))}
        </div>
        <img
          src="/icons/arrowdown-tertiary.svg"
          alt="Arrow Pointing Down"
          className={showBottom ? "arrow down" : "arrow up"}
          onClick={() => toggleBottom(!showBottom)}
        />
      </div>
      {showBottom && (
        <div className="bottom-section">
          <p>
            I want{" "}
            <span
              className="persona-name"
              style={{ backgroundColor: persona.colorCode }}
            >
              {persona.name}
            </span>{" "}
            to <span className="action">{persona.getToBy.to}</span> by{" "}
            <span className="action">{persona.getToBy.by}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export const PersonaSelector = () => {
  const { content, dispatch } = useProjectDetails();
  const [active, setActive] = useState(0);

  const handleChange = (i) => {
    setActive(i.target.value);
    dispatch({ type: "SET_AP", activePersona: i.target.value });
  };

  useEffect(() => {
    setActive(content.activePersona);
  }, [content]);

  if (content.personas.length === 0) {
    return <p>Please Define a Persona first</p>;
  }

  return (
    <div className="persona-selector">
      <select
        value={active}
        onChange={handleChange}
        style={{ backgroundColor: content.personas[active].colorCode }}
      >
        {content.personas.map((p, i) => (
          <option value={i} style={{ background: p.colorCode }}>
            {p.name}
          </option>
        ))}
      </select>
    </div>
  );
};
