import React, { useState } from "react";
import { PersonaBreakdown } from "./persona";
import { Page } from "./misc";
import { useProjectDetails, useProjectState } from "../data/projectHook";
import produce from "immer";
import axios from "axios";

export const BrainDumpWrapper = () => {
  const { content } = useProjectDetails();
  const active = content.personas[content.activePersona];

  if (!active) {
    return (
      <Page>
        <p>Please add a persona first</p>
      </Page>
    );
  }

  return (
    <Page>
      <div className="braindump">
        <PersonaBreakdown />
        <div className="braindump-wrapper">
          {active.concerns.length > 0 ? (
            active.concerns.map((p, index) => (
              <BrainDump
                project_id={content.project_id}
                persona={active}
                activePersona={content.activePersona}
                key={p._id}
                index={index}
                data={p}
              />
            ))
          ) : (
            <p>Please add some concerns to this persona first</p>
          )}
        </div>
      </div>
    </Page>
  );
};

const BrainDump = ({ project_id, persona, activePersona, index, data }) => {
  const [input, setInput] = useState("");
  const [showForm, setShowForm] = useState(false);
  const { state, mutateProjectState } = useProjectState(project_id);

  const addTopic = () => {
    if (input === "") {
      return;
    }

    let newState = produce(state, (draft) => {
      draft.personas[activePersona].concerns[index].topics.push({
        text: input,
        _id: input,
      });
    });
    mutateProjectState(newState, false);

    try {
      axios.post("/api/pv/addTopic", {
        text: input,
        project_id: state._id,
        concern_id: data._id,
        persona_id: persona._id,
      });
      setInput("");
    } catch (e) {
      console.log(e.message);
    }
  };
  return (
    <div className="braindump-item-wrapper">
      <Disclaimer brand={state.brand.name} color={persona.colorCode} />
      <div className="braindump-item">
        <p className="pretitle">{data.type}</p>
        <h4>{data.text}</h4>
        {data.topics &&
          data.topics.length > 0 &&
          data.topics.map((topic) => (
            <p
              key={topic._id}
              className="topic"
              style={{ backgroundColor: persona.colorCode }}
            >
              {topic.text}
            </p>
          ))}

        {showForm ? (
          <div className="topic-form">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button onClick={addTopic} className="circle">
              +
            </button>
          </div>
        ) : (
          <button className="add-topic" onClick={() => setShowForm(true)}>
            +
          </button>
        )}
      </div>
    </div>
  );
};

const Disclaimer = ({ brand, color }) => (
  <p className="disclaimer">
    While{" "}
    <span
      className="underline"
      style={{ color: color, borderBottom: `2px solid ${color}` }}
    >
      providing resources and tools that can motivate
    </span>{" "}
    what can{" "}
    <span
      className="underline"
      style={{ color: color, borderBottom: `2px solid ${color}` }}
    >
      {brand}
    </span>{" "}
    authentically say to help with:{" "}
  </p>
);
