export const componentData = [
  {
    id: "START",
    step: 1,
    title: "Set up personas",
    body: "Who are your brand speaking to? \n\n \n\n Crafting your audiences as real-life nuanced personas is an important foundation in making sure your strategy adresses the needs and wants of your audience. \n\n  Who are they? \n\n What do they do? \n\n What are they motivated by? \n\n What are their personal goals? \n\n\n _(feel free to come back to this step to fine-tune your personas' goals, this entire tool is an iterative process!)_",
    canShow: function (state) {
      return state.personas.length > 0;
    },
  },
  {
    id: "CHALLENGES",
    step: 2,
    title: "Your audience's needs and challenges",
    body: "Build more nuance to your identified personas. \n\n By identifying personas' challenges and opportunities, we start to open up spaces, angles and topics that the brand can communicate in. \n\n _(feel free to come back to this step to fine-tune your personas' goals, this entire tool is an iterative process!)_",
    canShow: function (state) {
      return state.personas.length > 0;
    },
  },
  {
    id: "GTB",
    step: 3,
    title: "Define Get-To-By",
    body: "For each persona, succintly define a key objective (behavioural, perception or feeling) and your brand's role and authority in reaching that objective.\n\n _In order to proceed, you'll need to fill out a Get-To-By for each of your personas._",
    canShow: function (state) {
      return state.personas[0].goals.length > 0;
    },
  },
  {
    id: "BRAINDUMP",
    step: 4,
    title: "Guided brain dump",
    body: "Congrats, you have thoroughly defined your audiences, identified the change you want from them, and made sure that you can authentically exact such change. \n\n This step now helps you cover each persona develop thought-starters, 'talking points', ideas, that could further expand into effective solutions.",
    canShow: function (state) {
      let counter = 0;
      state.personas.forEach((persona) => {
        if (persona.getToBy) {
          counter++;
        }
      });

      return state.personas.length === counter;
    },
  },
  {
    id: "THEMEBOARD",
    step: 5,
    title: "organise your thoughts",
    body: "Whew! \n\n Now that you have penned down all your thoughts on the table, have a look at all of them. You would notice that some thoughts and ideas are more developed than others. \n\n First, organise all the thoughts into themes. \n\n - Are there similar topics that are emerging?\n\n - What are some of the thoughts that can be grouped together? \n\n Then, evaluate if there are themes that are important to your brand that are not included.",
    canShow: function (state) {
      let personasWithTopics = state.personas.filter(
        (p) => p.topics.length > 0
      );
      return personasWithTopics.length > 0;
    },
  },
  {
    id: "CHANNELS",
    step: 6,
    title: "Add a channel to your topic",
    body: "Now that you have organised each thought into the role they play in your content strategy, click on the relevant channels that you feel best suits your message.",
    canShow: function (state) {
      return state.themes.length > 0;
    },
  },
  {
    id: "SUMMARY",
    step: 7,
    title: "Content Summary",
    body: "Here is an overview of the content pillars that your brand can authentically own. They are true to what your audience cares about and relevant to their digital footprint. \n\n Click on it to track back to see where it all started. \n\n Continue to integrate and tweak each inputs at each stage to paint the most detailed picture of your content strategy.",
    canShow: function (state) {
      return state.themes.length > 0;
    },
  },
];

export const social_channels = [
  "TIKTOK",
  "TWITTER",
  "INSTAGRAM",
  "YOUTUBE",
  "FACEBOOK",
  "LINKEDIN",
  "TELEGRAM",
  "DISCORD",
];
