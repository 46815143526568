export const Page = (props) => (
  <main className="project-content">{props.children}</main>
);

export const SocialCheckbox = ({ name, checked, set }) => {
  const handleClick = () => {
    set(name, !checked);
  };

  return (
    <img
      src={
        checked
          ? `/icons/social/${name.toUpperCase()}-ACTIVE.svg`
          : `/icons/social/${name.toUpperCase()}-INACTIVE.svg`
      }
      alt={name}
      onClick={handleClick}
      className="social-icon"
    />
  );
};

export const SocialThemeIcon = ({ name, active, add }) => (
  <img
    src={
      active
        ? `/icons/social/${name.toUpperCase()}-ACTIVE.svg`
        : `/icons/social/${name.toUpperCase()}-INACTIVE.svg`
    }
    alt={name}
    onClick={add}
    className="social-icon"
  />
);
