import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../data/auth";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

const Login = (props) => {
  const { state, dispatch } = useAuth();
  const [err, setErr] = useState("");
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    axios
      .post("/api/pb/login", data)
      .then((resp) => {
        dispatch({ type: "SET_TOKEN", token: resp.data.token, loggedIn: true });
      })
      .catch((e) => setErr(e.response.data));
  };

  if (state.loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login">
      <div className="sidebar">
        <img src="/logo.png" alt="The Good Content Tool" />
      </div>
      <main className="content">
        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
          <h2>Please login to continue...</h2>
          {err !== "" && <p className="error">{err}</p>}
          <input
            type="email"
            placeholder="Email Address"
            {...register("email", { required: true, pattern: "" })}
          />
          <input
            type="password"
            {...register("password", { required: true })}
            placeholder="Password"
          />
          <input type="submit" value="Submit" className="btn primary" />
        </form>
      </main>
    </div>
  );
};

export default Login;
