import { mutate } from "swr";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Slider from "react-input-slider";
import { social_channels } from "../data/variables";
import { SocialCheckbox } from "./misc";

export const NewBrandForm = ({ toggle }) => {
  const selectOptions = [
    "AWARENESS",
    "PERCEPTION",
    "INFORMATION",
    "ENGAGEMENT",
    "INTERACTION",
    "SALES/LEADS",
    "CREDIBILITY",
  ];
  const { register, handleSubmit, watch, control, setValue } = useForm();

  const selectedOpts = watch(["roles-1", "roles-2", "roles-3"]);

  const onSubmit = async (data) => {
    const asArray = Object.entries(data);

    let socials = [];

    asArray.filter(([key, value]) => {
      if (value === true) {
        socials.push(key);
        return key;
      } else {
        return false;
      }
    });

    if (socials.length < 1) {
      alert("You need to select at least one social channel");
      return;
    }

    if (socials.indexOf("role") !== -1) {
      socials.splice(socials.indexOf("role"), 1);
    }

    let queryData = {
      name: data.name,
      channels: socials,
      roleOfSocialPlatforms: data.role === "true",
      brandGoalsRanking: [{ text: data["roles-1"], priority: 1 }],
    };

    if (data["roles-2"] !== "NULL") {
      queryData.brandGoalsRanking.push({ text: data["roles-2"], priority: 2 });
    }

    if (data["roles-3"] !== "NULL") {
      queryData.brandGoalsRanking.push({ text: data["roles-3"], priority: 3 });
    }

    try {
      let res = await axios.post("/api/pv/createBrand", queryData);
      if (res.data && res.data.msg === "BRAND_ADDED") {
        mutate("/api/pv/listBrands");
        toggle(() => false);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <form className="new-brand-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="item-wrapper">
        <input
          type="text"
          {...register("name", { required: true })}
          className="brand-title-input"
        />
        <p className="label">Brand Name</p>
      </div>
      <div className="item-wrapper">
        <p className="label">Where are you currently present</p>
        <div className="icon-list">
          {social_channels.map((c) => (
            <Controller
              key={c}
              control={control}
              defaultValue={false}
              name={c}
              render={({ field: { value } }) => (
                <SocialCheckbox name={c} checked={value} set={setValue} />
              )}
            />
          ))}
        </div>
      </div>
      <div className="item-wrapper">
        <p className="label">
          Do you understand the role these social platforms play in your brand
          ecosystem?
        </p>
        <Controller
          name="role"
          control={control}
          defaultValue={false}
          render={({ field: { value } }) => (
            <p className="role">
              <p
                className={value === true ? "active" : "inactive"}
                onClick={() => setValue("role", true)}
              >
                Y
              </p>{" "}
              /{" "}
              <p
                className={value === false ? "active" : "inactive"}
                onClick={() => setValue("role", false)}
              >
                N
              </p>
            </p>
          )}
        />
      </div>
      <div className="item-wrapper">
        <p className="label">Rank your brand goals</p>
        <div className="select-items">
          <div className="select-item">
            <p className="prenum">1</p>
            <select {...register("roles-1")}>
              <option value="NULL" disabled selected>
                Select One
              </option>
              {selectOptions.map((x) => (
                <option
                  key={x}
                  value={x}
                  disabled={selectedOpts.indexOf(x) !== -1}
                >
                  {x}
                </option>
              ))}
            </select>
          </div>
          <div className="select-item">
            <p className="prenum">2</p>
            <select {...register("roles-2")}>
              <option value="NULL" disabled selected hidden>
                Optional
              </option>
              {selectOptions.map((x) => (
                <option
                  key={x}
                  value={x}
                  disabled={selectedOpts.indexOf(x) !== -1}
                >
                  {x}
                </option>
              ))}
            </select>
          </div>
          <div className="select-item">
            <p className="prenum">3</p>
            <select {...register("roles-3")}>
              <option value="NULL" disabled selected hidden>
                Optional
              </option>
              {selectOptions.map((x) => (
                <option
                  key={x}
                  value={x}
                  disabled={selectedOpts.indexOf(x) !== -1}
                >
                  {x}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="btns">
        <input type="submit" value="Add Brand" className="btn primary" />
        <button onClick={() => toggle(false)}>Close</button>
      </div>
    </form>
  );
};

export const NewProjectForm = ({ toggle, brand }) => {
  const { register, handleSubmit, control, watch } = useForm();
  const weeks = watch("timeFrame", 12);
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      let newData = { ...data, brand };
      let res = await axios.post("/api/pv/createNewProject", newData);
      history.push(`/project/${res.data.project_id}`);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <form className="new-project-form" onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        placeholder="Project Name"
        {...register("name", { required: true })}
      />
      <p className="label">Set the timeframe we should be exploring </p>
      <Controller
        control={control}
        name="timeFrame"
        defaultValue={12}
        render={({ field: { value, onChange } }) => (
          <Slider
            axis={"x"}
            xmax={52}
            xmin={1}
            xstep={1}
            onChange={({ x }) => onChange(x)}
            x={value}
            styles={{
              track: {
                border: "2px solid #d39b92",
                backgroundColor: "#e9cbc1",
              },
              active: {
                backgroundColor: "#264f46",
              },
              thumb: {
                backgroundColor: "#e9cbc1",
                backgroundImage: "url('/slider_button.svg')",
                backgroundSize: "contain",
              },
            }}
          />
        )}
      />
      <p className="label">
        <span style={{ color: "#264f46" }}> {weeks}</span> weeks
      </p>
      <input type="submit" value="Add Project" className="btn primary" />
    </form>
  );
};

export const NewPersonaForm = ({ project_id, toggle, mutate }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const obj = { ...data, project_id };

    try {
      await axios.post("/api/pv/defineNewPersona", obj);
      mutate();
      if (toggle) {
        toggle(() => false);
      }
    } catch (e) {
      console.log(e);
      toggle(() => false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="persona-form">
      <input type="text" {...register("name", { required: true })} />
      <p className="label">Persona Name</p>
      <input type="text" {...register("role", { required: true })} />
      <p className="label">Persona Role</p>
      <input type="submit" value="Add New Persona" className="btn " />
    </form>
  );
};
