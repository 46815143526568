import React, { createContext, useContext, useReducer } from "react";

const AuthContext = createContext();
const initialState = { token: null, loggedIn: false };

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        token: action.token,
        loggedIn: true,
      };
    case "REMOVE_TOKEN":
      return { token: null, loggedIn: false };
    default:
      throw new Error(`Unhandled Action Type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
