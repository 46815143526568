import React, { createContext, useContext, useReducer } from "react";
import axios from "axios";
import useSWR from "swr";

const ProjectContext = createContext();

const initialContent = { project_id: "", activePersona: 0, personas: [] };

const reducer = (content, action) => {
  switch (action.type) {
    case "UPDATE_PROJECT":
      return {
        project_id: action.project_id,
        personas: action.personas,
        activePersona: content.activePersona,
      };
    case "SET_AP":
      return {
        project_id: content.project_id,
        activePersona: action.activePersona,
        personas: content.personas,
      };
    case "INCREMENT_AP":
      console.log(content.activePersona);
      console.log(content.personas.length - 1);
      if (content.activePersona < content.personas.length - 1) {
        return {
          project_id: content.project_id,
          personas: content.personas,
          activePersona: content.activePersona + 1,
        };
      } else {
        return {
          project_id: content.project_id,
          personas: content.personas,
          activePersona: 0,
        };
      }
    case "UNSET_DATA":
      return initialContent;
    default:
      throw new Error(`Unhandled Action Type: ${action.type}`);
  }
};

export const ProjectProvider = ({ children }) => {
  const [content, dispatch] = useReducer(reducer, initialContent);

  return (
    <ProjectContext.Provider value={{ content, dispatch }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectDetails = () => useContext(ProjectContext);

export async function fetcher(url) {
  return axios.get(url).then((res) => res.data);
}

export const useProjectState = (id) => {
  const { data, error, mutate } = useSWR(
    `/api/pv/fetchProjectState/${id}`,
    fetcher
  );

  return {
    state: data,
    isLoading: !error && !data,
    isError: error,
    mutateProjectState: mutate,
  };
};
