import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProjectState, useProjectDetails } from "../data/projectHook";
import { GTB } from "../components/gtb";
import { Challenges } from "../components/challenges";
import { SocialLinking } from "../components/social";
import { PersonaContainer } from "../components/persona";
import { BrainDumpWrapper } from "../components/braindump";
import { NewPersonaForm } from "../components/forms";
import Summary from "../components/summary";
import { componentData } from "../data/variables.js";
import ThemeBoard from "../components/themeboard";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const Project = () => {
  const { project_id } = useParams();
  const { dispatch } = useProjectDetails();
  const { state, isLoading, isError, mutateProjectState } = useProjectState(
    project_id
  );
  const [activeSection, setActiveSection] = useState(0);
  const [activeComponent, setActiveComponent] = useState(<PersonaContainer />);
  const [canGoNext, setCanGo] = useState(false);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "UPDATE_PROJECT",
        project_id,
        personas: state.personas,
      });
      checkIfCanGo();
    }

    switch (activeSection) {
      case 0:
        setActiveComponent(<PersonaContainer />);
        break;
      case 1:
        setActiveComponent(<Challenges />);
        break;
      case 2:
        setActiveComponent(<GTB state={state} mutate={mutateProjectState} />);
        break;
      case 3:
        setActiveComponent(<BrainDumpWrapper />);
        break;
      case 4:
        setActiveComponent(<ThemeBoard />);
        break;
      case 5:
        setActiveComponent(
          <SocialLinking data={state.themes} channels={state.brand.channels} />
        );
        break;
      case 6:
        setActiveComponent(<Summary />);
        break;
      default:
        setActiveComponent(<p>TODO: BUILD</p>);
        break;
    }
  }, [state, dispatch, project_id, activeSection, mutateProjectState]);

  const checkIfCanGo = () => {
    let nextSection = activeSection + 1;
    let nextSectionData = componentData[nextSection];

    if (nextSectionData !== undefined) {
      setCanGo(nextSectionData.canShow(state));
    } else if (activeSection === 6) {
      setCanGo(false);
    }
  };

  const handleNext = () => {
    setActiveSection(activeSection + 1);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>There was an error, {isError.message}</p>;
  }

  if (state.personas.length === 0) {
    return (
      <div className="project">
        <div className="sidebar">
          <Link to="/">
            <img
              src="/logo.png"
              alt="The Good Content Tool"
              className="sidebar-image"
            />
          </Link>

          <div className="sidebar-body">
            <p>Welcome!</p>
            <p>
              Since this is an empty project, let's start by creating a persona.
            </p>
            <p>Who are your brand speaking to?</p>
            <p>
              Crafting your audiences as real-life nuanced personas is an
              important foundation in making sure your strategy addresses the
              needs and wants of your audience.
            </p>
            <p>Who are they? What do they do?</p>
          </div>
        </div>
        <div className="project-content">
          <div className="persona-entry">
            <NewPersonaForm
              project_id={project_id}
              state={state}
              mutate={mutateProjectState}
            />
          </div>
        </div>
      </div>
    );
  }

  if (state) {
    return (
      <div className="project">
        <Sidebar
          activeSection={componentData[activeSection]}
          set={setActiveSection}
          state={state}
          asi={activeSection}
        />
        <div className="content-wrapper">
          {activeComponent}
          <div className="bottom-action">
            <button
              className={`btn continue ${!canGoNext ? "disabled" : "enabled"}`}
              onClick={handleNext}
              disabled={!canGoNext}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
};

const Sidebar = ({ activeSection, set, state, asi }) => {
  return (
    <div className="sidebar">
      <Link to="/">
        <img
          src="/logo.png"
          alt="The Good Content Tool"
          className="sidebar-image"
        />
      </Link>
      <div className="listing">
        {componentData.map((item, index) => (
          <div className="sidebar-item">
            <h3
              key={item.id}
              onClick={() => {
                if (item.canShow(state)) {
                  set(index);
                }
              }}
              className={`${index === asi ? "active " : "inactive "} ${
                item.canShow(state) ? "enabled" : "disabled"
              }`}
            >
              step {item.step}
            </h3>
            {asi === index && (
              <div className="body">
                <h1>{activeSection.title}</h1>
                <ReactMarkdown>{activeSection.body}</ReactMarkdown>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
